import React from 'react';
import styled from 'styled-components';
import developer_logo from '../../images/developer_animation.png';
import github_logo from '../../images/github_logo.png';
import linkedin_logo from '../../images/linkedin_logo.png';
import google_play_logo from '../../images/google_play_logo.png';
import review1 from '../../images/review1.png';
import review2 from '../../images/review2.png';
import review3 from '../../images/review3.png';
import HeroBgAnimation from '../HeroBgAnimation'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  padding: 0px 0px 60px 0px;
  @media (max-width: 960px) {
    margin-bottom: 50px;
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 40px 0px 0px 0px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const TitleQuestion = styled.div`
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin: 60px 20px 20px 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 60px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  margin: 0px 20px 0px 20px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const DevName = styled.div`
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const IconLink = styled.a`
  width: 35px; // Adjust the size as needed
  height: 35px; // Adjust the size as needed
`;

const GitHubIcon = styled(IconLink)`
  background-image: url(${github_logo});
  background-size: cover;
`;

const LinkedInIcon = styled(IconLink)`
  background-image: url(${linkedin_logo});
  background-size: cover;
`;

const GooglePlayIcon = styled(IconLink)`
  background-image: url(${google_play_logo});
  background-size: cover;
`;

const DeveloperLogo = styled.img`
  width: 300px; // Adjust the size as needed
  height: 300px; // Adjust the size as needed
  border-radius: 50%;
  object-fit: cover;
  margin: 30px;
`;

const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: row; // Display reviews in a row
  justify-content: space-between;
  align-items: center;
  margin: 40px 40px 65px 40px;
  gap: 20px; // Adjust the gap between reviews
  @media (max-width: 960px) {
    flex-direction: column; // Stack reviews on medium-sized screens
  }
`;

const ReviewContainer = styled.div`
  width: 100%; // Ensure the review container takes the full width on medium-sized screens
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px; // Add margin between individual reviews
`;


const Review = styled.div``;

const ReviewAuthor = styled.p`
  font-size: 16px;
  margin-top: 10px;
  color: ${({ theme }) => theme.text_secondary};
`;

const ReviewText = styled.p`
  font-size: 18px;
  font-style: italic;
  margin-top: 15px;
  color: ${({ theme }) => theme.text_primary};
`;

const ReviewDate = styled.p`
  font-size: 14px;
  margin-top: 10px;
  color: ${({ theme }) => theme.text_secondary};
`;

const PhotoReview = styled.img`
  width: 30px; // Adjust the size as needed
  height: 30px; // Adjust the size as needed
  border-radius: 50%;
  object-fit: cover;
  margin: 10px;
`;

const Index = () => {
  return (
    <Container id="developer">
      <Wrapper>
        <Title>Developer</Title>
        
        <Desc>
          <span className='developer_quote'>Keep learning, keep growing, and stay curious. The journey of knowledge is the path to success. </span>

        </Desc>
        <DeveloperLogo src={developer_logo} alt="Developer Logo" 
        />
        
        <DevName>
          Alexandru Epure
        </DevName>
        <SocialLinks>
          <GitHubIcon href="https://github.com/alexepure13/" />
          <LinkedInIcon href="https://www.linkedin.com/in/alexandru-epure-3aa6aa151/" />
          <GooglePlayIcon href="https://play.google.com/store/apps/developer?id=NeXT64+Software" />
        </SocialLinks>
        <TitleQuestion>What do people say about the developed applications?</TitleQuestion>
        <ReviewsContainer>
          <ReviewContainer>
            <PhotoReview src={review1} alt="Review 1" />
            <ReviewAuthor>Iustina Elena Costan</ReviewAuthor>
            <ReviewText>
              "Este super tare, te ajută să intelegi operele și sa le inveti mult mai ușor."
            </ReviewText>
            <ReviewDate>User Review</ReviewDate>
          </ReviewContainer>
          <ReviewContainer>
            <PhotoReview src={review2} alt="Review 2" />
            <ReviewAuthor>Victor Grigoras</ReviewAuthor>
            <ReviewText>
              "O aplicație super!"
            </ReviewText>
            <ReviewDate>User Review</ReviewDate>
          </ReviewContainer>
          <ReviewContainer>
            <PhotoReview src={review3} alt="Review 3" />
            <ReviewAuthor>Eduard Balint</ReviewAuthor>
            <ReviewText>
              "O aplicație care chiar te ajută, materia este foarte bine structurată și pe înțelesul tuturor!"
            </ReviewText>
            <ReviewDate>User Review</ReviewDate>
          </ReviewContainer>
        </ReviewsContainer>
        <Desc>
          <span className='google_play_apps_director_content'>As we ring out another year, I would like to take a moment to congratulate all of you for an incredible 12 months. Thank you for continuing to inspire us with the outstanding and delightful apps and games you build on Android and Google Play. </span>
        </Desc>
        <Desc>
          <span className='google_play_apps_director'>Purnima Kochikar,</span><br></br> <span className='google_play_apps_director_sub'>Director, Google Play Apps & Games Business Development</span>
        </Desc>
      </Wrapper>
    </Container>
  );
};

export default Index;

