import React from 'react';
import styled from 'styled-components';
import mail from '../../images/mail.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 0px 0px 80px 0px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const ContactButton = styled.a`
  text-decoration: none;
  width: 95%;
  max-width: 300px;
  text-align: center;
  padding: 8px 0;
  color: ${({ theme }) => theme.white};
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 20px 20px 40px;
  transition: all 0.2s ease-in-out !important;
  background: #0E7FF0;
  background: linear-gradient(225deg, #0E7FF0 0%, #0E7FF0 100%);
  background: -moz-linear-gradient(225deg, #0E7FF0 0%, #0E7FF0 100%);
  background: -webkit-linear-gradient(225deg, #0E7FF0 0%, #0E7FF0 100%);
  box-shadow: 20px 20px 60px #1F2634, -20px -20px 60px #1F2634;
  &:hover {
    transform: scale(1.05);
    transition: all 0.4s ease-in-out;
    box-shadow: 20px 20px 60px #1F2634;
    filter: brightness(1);
  }

  @media (max-width: 640px) {
    padding: 7px 0;
    font-size: 18px;
  }
`;

const MailIcon = styled.img`
  width: 50px; // Adjust the size as needed
  height: 50px; // Adjust the size as needed
  object-fit: cover;
`;

const Contact = () => {
  const emailTo = 'alexepure70@gmail.com';

  return (
    <Container>
      <Wrapper>
        <Title>Contact</Title>
        <Desc>Don't hesitate to contact for any questions!</Desc>
        <MailIcon src={mail} alt="Developer Logo" />
        <ContactButton href={`mailto:${emailTo}`}>Send Email.</ContactButton>
      </Wrapper>
    </Container>
  );
};

export default Contact;
