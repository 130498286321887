import React, { useState, useEffect, useRef } from 'react';
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle';
import ProjectCard from '../Cards/ProjectCards';
import { projects } from '../../data/constants';

const Projects = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState('all');
  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target) && !e.target.href) {
      setOpenModal({ state: false, project: null });
    }
  };
  

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <Container id="projects">
      <Wrapper ref={modalRef}>
        <Title>Projects</Title>
        <Desc>
        From web applications to Android apps, here are a few projects:
        </Desc>
        <ToggleButtonGroup>
          {toggle === 'all' ? (
            <ToggleButton active value="all" onClick={() => setToggle('all')}>
              All
            </ToggleButton>
          ) : (
            <ToggleButton value="all" onClick={() => setToggle('all')}>
              All
            </ToggleButton>
          )}
          <Divider />
          {toggle === 'web app' ? (
            <ToggleButton active value="web app" onClick={() => setToggle('web app')}>
              WEB APP'S
            </ToggleButton>
          ) : (
            <ToggleButton value="web app" onClick={() => setToggle('web app')}>
              WEB APP'S
            </ToggleButton>
          )}
          <Divider />
          {toggle === 'android app' ? (
            <ToggleButton active value="android app" onClick={() => setToggle('android app')}>
              ANDROID APP'S
            </ToggleButton>
          ) : (
            <ToggleButton value="android app" onClick={() => setToggle('android app')}>
              ANDROID APP'S
            </ToggleButton>
          )}
          <Divider />
          {toggle === 'machine learning' ? (
            <ToggleButton active value="machine learning" onClick={() => setToggle('win app')}>
              WINDOWS APP'S
            </ToggleButton>
          ) : (
            <ToggleButton value="machine learning" onClick={() => setToggle('win app')}>
              WINDOWS APP'S
            </ToggleButton>
          )}
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'all' && projects.map((project) => (
            <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal} />
          ))}
          {projects
            .filter((item) => item.category === toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal} />
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Projects;