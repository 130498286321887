import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import downloads_meter from '../../images/downloads_meter.png';
import review_meter from '../../images/reviews_meter.png';
import stars_meter from '../../images/stars_meter.png';
import projects_meter from '../../images/projects_meter.png';
import colina_parking from '../../images/parcare_colina.png';
import bac_romana_news from '../../images/news_bac_romana.19897971fbc5e0cf8196.jpg';
import photo_map_news from '../../images/news_photo_map.38861839ebecb13e54cf.jpg';
import app_monitor_news from '../../images/app_monitoring_software.png';
import motitask from '../../images/motitask_android.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 80px 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 80px 0;
  gap: 18px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin: 30px 20px 0px 20px;

  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  margin: 0px 20px 20px 20px;

  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 80%;
  height: 180px;
  object-fit: contain;
  margin: 10px;
  padding: 20px;
`;

const CustomText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.text_primary};
`;

const NewsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px; 
  margin: 20px 30px 20px 30px; /* Center align the news container */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const NewsBox = styled.div`
  width: calc(33.33% - 20px);
  text-align: center;
  margin: 10px auto;
  /* Add box-shadow for the shadow effect */
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.4);
  padding: 20px;
  background-color: #171721;
  border-radius: 15px;
  /* Remove the border property and update the border-color */
  border-color: transparent;
  @media (max-width: 768px) {
    width: 100%;
  }
`;


const NewsTitle = styled.h2`
  font-size: 24px;
  color: #B1B2B2;
  text-align: left;
  margin: 10px 15px 20px 15px;
`;

const NewsDescription = styled.p`
  font-size: 16px;
  white-space: pre-line; 
  text-align: left;
  color: #B0B2B1;
  margin: 0 15px 0 15px;
`;

const NewsImage = styled.img`
  width: 95%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin: 10px 0;
`;

const NewsDate = styled.p`
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  margin: 10px 15px 0 15px;
  color: #888;
`;

const images = [
  { image: downloads_meter, text: 'Downloads' },
  { image: review_meter, text: 'Reviews' },
  { image: stars_meter, text: 'Stars' },
  { image: projects_meter, text: 'Projects' },
];

const newsData = [
  {
    image: bac_romana_news,
    title: 'Bac Română 2024',
    description: 'The website bacromana.ro has been updated.\nNews: New interface and fresh content.',
    date: 'October 7, 2023"',
  },
  {
    image: app_monitor_news,
    title: 'App Monitoring Software',
    description: 'Software for monitoring desktop and mobile applications.\nPlatform: Windows\nStatus: Ready for use.',
    date: 'October 13, 2022',
  },
  {
    image: motitask,
    title: 'MotiTask',
    description: '#MotivationApp #TimeManagement #TaskManagement #ProgressTracking\nStatus: Currently in development',
    date: 'November 11, 2023',
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 20,
  },
};

const index = () => {
  return (
    <Container id="news">
      <Wrapper>
        <Title>Some Milestone</Title>
        <Desc>NXT64 project contains <span className='project-counter'>15+</span> apps and more.</Desc>
        <CarouselContainer>
          <Carousel responsive={responsive} infinite showDots={false} autoPlay arrows={false}>
            {images.map((item, index) => (
              <CarouselWrapper key={index}>
                <Image src={item.image} alt={`Image ${index + 1}`} />
                <CustomText>{item.text}</CustomText>
              </CarouselWrapper>
            ))}
          </Carousel>
        </CarouselContainer>
        <Title>Latest News</Title>
        <Desc>The mentioned updates include an overhaul of the bacromana.ro website, the release of application monitoring software, and an Android app called "MotiTask" in development.</Desc>
      <NewsContainer>
        {newsData.map((item, index) => (
          <NewsBox key={index}>
            <NewsImage src={item.image} alt={`News Image ${index + 1}`} />
            <NewsTitle>{item.title}</NewsTitle>
            <NewsDescription>{item.description}</NewsDescription>
            <NewsDate>{item.date}</NewsDate>
          </NewsBox>
        ))}
      </NewsContainer>
      </Wrapper>
    </Container>
  );
};

export default index;
